import React, { lazy } from "react";
import UserOrder from "../pages/other/profile/orders";
import UserOrderMessage from "../pages/other/profile/orders/ordermessage";
import UserOrderSent from "../pages/other/profile/orders/sentmessage";
import UserOrderDetails from "../pages/other/profile/orders/orderDetail";

// PROFESSIONAL_ROUTES
const ProfessionalHome = lazy(() => import("../pages/Professional/home/index"));
const ProfessionalProfile = lazy(() =>
  import("../pages/Professional/profile/profileinfo/index")
);
const ProfessionalContact = lazy(() =>
  import("../pages/Professional/profile/contact/index")
);
const ProfessionalNewPassword = lazy(() =>
  import("../pages/Professional/profile/newpassword/index")
);
const ProfessionalSocial = lazy(() =>
  import("../pages/Professional/profile/socialmedia/index")
);
const ProfessionalShippingAddress = lazy(() =>
  import("../pages/Professional/profile/shippingaddress/index")
);
const ProfessionalBillingInfo = lazy(() =>
  import("../pages/Professional/profile/billinginfo/index")
);
const ProfessionalBillingPayment = lazy(() =>
  import("../pages/Professional/profile/billingpayment/index")
);
const ProfessionalProject = lazy(() =>
  import("../pages/Professional/projects/project/index")
);
const ProfessionalProjectUpload = lazy(() =>
  import("../pages/Professional/projects/uploadproject/index")
);
const FindProfessional = lazy(() => import("../pages/Professional/find/index"));

const WelcomeProfessional = lazy(() =>
  import("../pages/other/WelcomeProfessional")
);
const ChooseYourPlan = lazy(() => import("../pages/other/Plans"));
const GetStarted = lazy(() => import("../pages/other/GetStarted"));

const ProfessionalIdeabooks = lazy(() =>
  import("../pages/Professional/ideabooks/ideabook")
);
const ProfessionalReview = lazy(() =>
  import("../pages/Professional/review/review")
);
const ProfessionalReviewRequest = lazy(() =>
  import("../pages/Professional/review/review/requestreview")
);
const ProfessionalPastReviewRequest = lazy(() =>
  import("../pages/Professional/review/review/pastreviewrequest")
);

const ProfessionalQuestion = lazy(() =>
  import("../pages/Professional/questions/index")
);
const ProfessionalUnanswerQuestion = lazy(() =>
  import("../pages/Professional/questions/unanswerquestion")
);
const ProfessionalActivityUpdate = lazy(() =>
  import("../pages/Professional/activity/index")
);
const ProfessionalActivityComments = lazy(() =>
  import("../pages/Professional/activity/activitycomment")
);
const ProfessionalActivityPosts = lazy(() =>
  import("../pages/Professional/activity/activitypostshare")
);
const ProfessionalActivityReviews = lazy(() =>
  import("../pages/Professional/activity/activityreviews")
);
const ProfessionalActivityPhoto = lazy(() =>
  import("../pages/Professional/activity/activityphoto")
);
const ProfessionalOrders = lazy(() =>
  import("../pages/Professional/orders/index")
);
const ProfessionalOrderMessage = lazy(() =>
  import("../pages/Professional/orders/ordermessage")
);
const ProfessionalOrdersSentMessage = lazy(() =>
  import("../pages/Professional/orders/sentmessage")
);
const ProfessionalVerifiedCalender = lazy(() =>
  import("../pages/Professional/verifiedcalender/index")
);

const ProfessionalList = lazy(() =>
  import("../pages/Professional/professionalList/index")
);
const Detail = lazy(() => import("../pages/Professional/detailpage"));
// PROFESSIONAL_ROUTES

// AUTH
const LoginSignUp = lazy(() => import("../pages/other/LoginSignUp"));
const ForgotPassword = lazy(() => import("../pages/other/ForgotPassword"));
const Verify = lazy(() => import("../pages/other/Verify"));
const ChangePassword = lazy(() => import("../pages/other/ChangePassword"));
// AUTH

// USER_ROUTES
// home pages
const HomeFashion = lazy(() => import("../pages/home/HomeFashion"));
const LocalContact = lazy(() => import("../product/LocalContact"));
const AllShop = lazy(() => import("../product/AllShop"));
const StoryDetails = lazy(() => import("../product/StoryDetails"));
const VideoDetails = lazy(() => import("../product/VideoDetails"));

// shop pages
const ShopGridStandard = lazy(() => import("../pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("../pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("../pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("../pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("../pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("../pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("../pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("../pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("../pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("../pages/shop-product/Product"));
const Furniture = lazy(() => import("../product/Furniture"));
const ProductTabLeft = lazy(() =>
  import("../pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("../pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("../pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("../pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("../pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("../pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("../pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("../pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("../pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("../pages/other/About"));
const Contact = lazy(() => import("../pages/other/Contact"));
// const MyAccount = lazy(() => import("../pages/other/MyAccount"));
// UserProfileRoute
const UserProfile = lazy(() =>
  import("../pages/other/profile/profileinfo/index")
);
const UserContact = lazy(() =>
  import("../pages/other/profile/profileinfo/index")
);
const UserNewPassword = lazy(() =>
  import("../pages/other/profile/newpassword/index")
);
const UserSocial = lazy(() =>
  import("../pages/other/profile/socialmedia/index")
);
const UserShippingAddress = lazy(() =>
  import("../pages/other/profile/shippingaddress/index")
);
const UserBillingInfo = lazy(() =>
  import("../pages/other/profile/billinginfo/index")
);
const UserBillingPayment = lazy(() =>
  import("../pages/other/profile/billingpayment/index")
);

const Cart = lazy(() => import("../pages/other/Cart"));
const Wishlist = lazy(() => import("../pages/other/Wishlist"));
const Compare = lazy(() => import("../pages/other/Compare"));
const Checkout = lazy(() => import("../pages/other/Checkout"));

const NotFound = lazy(() => import("../pages/other/NotFound"));

const WelcomeHomeOwner = lazy(() => import("../pages/other/WelcomeHomeOwner"));
const Photos = lazy(() => import("../product/Photos"));
const PhotosDetails = lazy(() => import("../product/PhotosDetails"));
const ProfessionalDetail = lazy(() => import("../product/ProfessionalDetail"));

// USER_ROUTES

export const ProfessionalRoutes = [
  {
    path: "/professional/home",
    component: <ProfessionalHome />,
    isProtected: true,
  },
  {
    path: "/professional/profile",
    component: <ProfessionalProfile />,
    isProtected: true,
  },
  {
    path: "/professional/findProfessional",
    component: <FindProfessional />,
    isProtected: true,
  },
  {
    path: "/professional/contact",
    component: <ProfessionalContact />,
    isProtected: true,
  },
  {
    path: "/professional/projectsupload",
    component: <ProfessionalProjectUpload />,
    isProtected: true,
  },
  {
    path: "/professional/projects",
    component: <ProfessionalProject />,
    isProtected: true,
  },
  {
    path: "/professional/billingpayment/:id",
    component: <ProfessionalBillingPayment />,
    isProtected: true,
  },
  {
    path: "/professional/billinginfo",
    component: <ProfessionalBillingInfo />,
    isProtected: true,
  },
  {
    path: "/professional/shippingaddress",
    component: <ProfessionalShippingAddress />,
    isProtected: true,
  },
  {
    path: "/professional/socialmedia",
    component: <ProfessionalSocial />,
    isProtected: true,
  },
  {
    path: "/professional/newpassword",
    component: <ProfessionalNewPassword />,
    isProtected: true,
  },
  {
    path: "/professional/welcomeProfessional",
    component: <WelcomeProfessional />,
    isProtected: true,
  },
  {
    path: "/professional/choose-your-plans",
    component: <ChooseYourPlan />,
    isProtected: true,
  },
  {
    path: "/professional/professional-onBoarding",
    component: <GetStarted />,
    isProtected: true,
  },
  {
    path: "/professional/ideabooks",
    component: <ProfessionalIdeabooks />,
    isProtected: true,
  },
  {
    path: "/professional/review",
    component: <ProfessionalReview />,
    isProtected: true,
  },
  {
    path: "/professional/reviewrequest",
    component: <ProfessionalReviewRequest />,
    isProtected: true,
  },
  {
    path: "/professional/pastreviewrequest",
    component: <ProfessionalPastReviewRequest />,
    isProtected: true,
  },
  {
    path: "/professional/questions",
    component: <ProfessionalQuestion />,
    isProtected: true,
  },
  {
    path: "/professional/unansweredquestions",
    component: <ProfessionalUnanswerQuestion />,
    isProtected: true,
  },
  {
    path: "/professional/activityupdates",
    component: <ProfessionalActivityUpdate />,
    isProtected: true,
  },
  {
    path: "/professional/activitycomments",
    component: <ProfessionalActivityComments />,
    isProtected: true,
  },
  {
    path: "/professional/activitypostshare",
    component: <ProfessionalActivityPosts />,
    isProtected: true,
  },
  {
    path: "/professional/activityreviews",
    component: <ProfessionalActivityReviews />,
    isProtected: true,
  },
  {
    path: "/professional/activityphoto",
    component: <ProfessionalActivityPhoto />,
    isProtected: true,
  },
  {
    path: "/professional/orders",
    component: <ProfessionalOrders />,
    isProtected: true,
  },
  {
    path: "/professional/ordermessage",
    component: <ProfessionalOrderMessage />,
    isProtected: true,
  },
  {
    path: "/professional/ordersent",
    component: <ProfessionalOrdersSentMessage />,
    isProtected: true,
  },
  {
    path: "/professional/verifiedcalender",
    component: <ProfessionalVerifiedCalender />,
    isProtected: true,
  },
  {
    path: "/professional/list",
    component: <ProfessionalList />,
    isProtected: true,
  },
  {
    path: "/professional/Professional-Detail",
    component: <Detail />,
    isProtected: true,
  },
];

export const AuthRoutes = [
  { path: "/login-signup", component: <LoginSignUp />, isProtected: false },
  {
    path: "/forgotPassword",
    component: <ForgotPassword />,
    isProtected: false,
  },
  { path: "/verify/:token", component: <Verify />, isProtected: false },
  {
    path: "/ConfirmPassword/:token",
    component: <ChangePassword />,
    isProtected: false,
  },
  { path: "*", component: <NotFound />, isProtected: false },
];

export const UserRoutes = [
  { path: "/", component: <HomeFashion />, isProtected: false },
  { path: "/LocalContact", component: <LocalContact />, isProtected: false },
  { path: "/AllShop", component: <AllShop />, isProtected: false },
  { path: "/Blog/:slug", component: <StoryDetails />, isProtected: false },
  { path: "/video/:slug", component: <VideoDetails />, isProtected: false },
  { path: "/Shop/:slug", component: <ShopGridStandard />, isProtected: false },
  {
    path: "/shop-grid-filter",
    component: <ShopGridFilter />,
    isProtected: false,
  },
  {
    path: "/orders",
    component: <UserOrder />,
    isProtected: true,
  },
  {
    path: "/orders/:id",
    component: <UserOrderDetails />,
    isProtected: true,
  },
  {
    path: "/ordermessage",
    component: <UserOrderMessage />,
    isProtected: true,
  },
  {
    path: "/ordersent",
    component: <UserOrderSent />,
    isProtected: true,
  },
  {
    path: "/shop-grid-two-column",
    component: <ShopGridTwoColumn />,
    isProtected: false,
  },
  {
    path: "/shop-grid-no-sidebar",
    component: <ShopGridNoSidebar />,
    isProtected: false,
  },
  {
    path: "/shop-grid-full-width",
    component: <ShopGridFullWidth />,
    isProtected: false,
  },
  {
    path: "/shop-grid-right-sidebar",
    component: <ShopGridRightSidebar />,
    isProtected: false,
  },
  {
    path: "/shop-list-standard",
    component: <ShopListStandard />,
    isProtected: false,
  },
  {
    path: "/shop-list-full-width",
    component: <ShopListFullWidth />,
    isProtected: false,
  },
  {
    path: "/shop-list-two-column",
    component: <ShopListTwoColumn />,
    isProtected: false,
  },
  {
    path: "/product/Furniture/:slug",
    component: <Product />,
    isProtected: false,
  },
  { path: "/product/:slug", component: <Furniture />, isProtected: false },
  {
    path: "/product-tab-left/:id",
    component: <ProductTabLeft />,
    isProtected: false,
  },
  {
    path: "/product-tab-right/:id",
    component: <ProductTabRight />,
    isProtected: false,
  },
  {
    path: "/product-sticky/:id",
    component: <ProductSticky />,
    isProtected: false,
  },
  {
    path: "/product-slider/:id",
    component: <ProductSlider />,
    isProtected: false,
  },
  {
    path: "/product-fixed-image/:id",
    component: <ProductFixedImage />,
    isProtected: false,
  },
  { path: "/blog-standard", component: <BlogStandard />, isProtected: false },
  {
    path: "/blog-no-sidebar",
    component: <BlogNoSidebar />,
    isProtected: false,
  },
  {
    path: "/blog-right-sidebar",
    component: <BlogRightSidebar />,
    isProtected: false,
  },
  {
    path: "/blog-details-standard",
    component: <BlogDetailsStandard />,
    isProtected: false,
  },
  { path: "/about", component: <About />, isProtected: false },
  { path: "/contact", component: <Contact />, isProtected: false },
  { path: "/profile", component: <UserProfile />, isProtected: true },
  { path: "/usercontact", component: <UserContact />, isProtected: false },
  { path: "/newpassword", component: <UserNewPassword />, isProtected: false },
  { path: "/socialmedia", component: <UserSocial />, isProtected: false },
  {
    path: "/shippingaddress",
    component: <UserShippingAddress />,
    isProtected: false,
  },
  { path: "/billinginfo", component: <UserBillingInfo />, isProtected: false },
  {
    path: "/billingpayment/:id",
    component: <UserBillingPayment />,
    isProtected: false,
  },
  { path: "/cart", component: <Cart />, isProtected: false },
  { path: "/wishlist", component: <Wishlist />, isProtected: false },
  { path: "/compare", component: <Compare />, isProtected: false },
  { path: "/checkout", component: <Checkout />, isProtected: false },
  {
    path: "/welcomeHomeOwner",
    component: <WelcomeHomeOwner />,
    isProtected: false,
  },
  { path: "/Photos/:slug", component: <Photos />, isProtected: false },
  {
    path: "/Photos/Details/:slug",
    component: <PhotosDetails />,
    isProtected: false,
  },
  {
    path: "/ProfessionalDetail/:slug",
    component: <ProfessionalDetail />,
    isProtected: false,
  },
];
