import axios from "axios";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
    baseURL: "https://verifiedcalendar.dev-hi.xyz/api/",
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const Data = error?.response?.data;
        const errors = Data.errors;
        if (errors) {
            errors.map((e) => toast.error(e));
        } else {
            toast.error(Data.message);
        }
        return Promise.reject(error);
    }
);