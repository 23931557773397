import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ProfileHeader from "../../../../components/profile-components/Profileheader/ProfileHeader";
import SEO from "../../../../components/seo";
import { axiosInstance } from "../../../../config/https";
import LayoutOne from "../../../../layouts/LayoutOne";
import Breadcrumb from "../../../../wrappers/breadcrumb/Breadcrumb";
import { useEffect } from "react";

const UserOrder = () => {
  const [order, setOrder] = useState([]);
  const getallOrder = () => {
    axiosInstance.get(`customer/orders`).then((e) => {
      const Data = e?.data;
      console.log(Data, "GetAllorder");
      if (e.status === 200) {
        setOrder(Data?.orders);
      }
    });
  };
  useEffect(() => {
    getallOrder();
  }, []);
  let { pathname } = useLocation();
  return (
    <Fragment>
      <SEO
        titleTemplate="User Home"
        description="User home of verified calendar"
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "User Profile",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <ProfileHeader />
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-lg-3">
              <h5 className="mb-4 " style={{ fontWeight: "bold" }}>
                Orders
              </h5>
              <h6>
                <Link to="/orders">Your Orders</Link>
              </h6>
              <h6>
                <Link to="/ordermessage">Order Messages</Link>
              </h6>
              <h6>
                <Link to="/ordersent">Sent Messages</Link>
              </h6>

              <hr style={{ width: "70%" }} />
              <h5 className="mb-4" style={{ fontWeight: "bold" }}>
                Shopping Account
              </h5>
              <h6>
                <Link to="/billinginfo">Billing Info</Link>
              </h6>
              <h6>
                <Link to="/shippingaddress">Shipping Addresses</Link>
              </h6>
              <div className="row">
                <div className="col-md-6 mt-2"></div>
              </div>

              <div className="row mt-4">{/* <BlogPosts /> */}</div>
            </div>

            <div className="col-lg-8 ">
              <h1 className="my-3">Order</h1>
              {order ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-content table-responsive cart-table-content">
                      <table>
                        <thead>
                          <tr>
                            <th>Order Id</th>
                            <th>Name</th>
                            <th>Total Price</th>
                            <th>Status</th>
                            <th>Tracking Id</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order?.map((Order) =>
                            Order?.order_detail?.map((orderItem) => {
                              let trackingid =
                                Math.floor(Math.random() * 9000000000) +
                                1000000000;
                              return (
                                <tr>
                                  <td>{orderItem.id}</td>

                                  <td className="product-name text-center text-capitalize">
                                    {Order.customer_order_detail.name}
                                  </td>

                                  <td className="product-price-cart">
                                    <i className="fa fa-dollar"> </i>
                                    {orderItem.price * orderItem.qty}
                                  </td>

                                  <td className="product-quantity">
                                    {Order.status}
                                  </td>

                                  <td className="product-remove">
                                    {trackingid}{" "}
                                  </td>
                                  <td className="product-remove">
                                    <Link to={"/orders/" + orderItem.id}>
                                      <div className="text-success">
                                        View Details
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <h6 className="mt-3">You do not have any order messages.</h6>
              )}
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default UserOrder;
