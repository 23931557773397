import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useEffect, useState } from "react";
import BaseUrl from "../../BaseUrl";
import axios from "axios";
import Swal from "sweetalert2";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  const [getidea, setGetidea] = useState([]);
  const [getprofessional, setGetprofessional] = useState([]);
  const [getshop, setGetShop] = useState([]);
  const { slug } = useParams();
  useEffect(() => {
    try {
      var config = {
        method: "get",
        url: `${BaseUrl.baseurl}getheader`,
      };
      axios(config)
        .then(function (response) {
          console.log(response, "setGetheader");
          setGetidea(response?.data?.idea);
          setGetprofessional(response?.data?.professionalCategory);
          setGetShop(response?.data?.shop);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        showCloseButton: true,
        toast: true,
        icon: "error",
        title: error?.response?.data?.message,
        animation: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    }
  }, [slug]);

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              {t("Get Ideas")}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>

            <ul className="mega-menu mega-menu-padding">
              {getidea.map((e) => {
                return (
                  <>
                    <li>
                      <ul>
                        <li className="mega-menu-title">
                          <Link
                            to={process.env.PUBLIC_URL + `/Photos/${e?.slug}`}
                          >
                            {e?.name}
                          </Link>
                        </li>
                        {e?.sub_categories?.map((e) => {
                          return (
                            <>
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    `/Photos/Details/${e?.slug}`
                                  }
                                >
                                  {e?.name}
                                </Link>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </li>
                  </>
                );
              })}
            </ul>
          </li>
          <li>
            <Link
              to={process.env.PUBLIC_URL + "/professional/findProfessional"}
            >
              {" "}
              {t("Find Professionals")}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              {getprofessional.map((e) => {
                return (
                  <>
                    <li>
                      <ul>
                        <li className="mega-menu-title">
                          <Link to={process.env.PUBLIC_URL + "/"}>
                            {e?.name}
                          </Link>
                        </li>
                        {e?.services?.map((e) => {
                          return (
                            <>
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/professional/list"
                                  }
                                >
                                  {e?.name}
                                </Link>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </li>
                  </>
                );
              })}
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/AllShop"}>
              {" "}
              {t("Shop Product")}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              {getshop.map((e) => {
                return (
                  <>
                    <li>
                      <ul>
                        <li className="mega-menu-title">
                          <Link
                            to={process.env.PUBLIC_URL + `/product/${e.slug}`}
                          >
                            {e?.name}
                          </Link>
                        </li>

                        {e?.categories?.map((e) => {
                          return (
                            <>
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL + `/Shop/${e.slug}`
                                  }
                                  onClick={() => {
                                    localStorage.setItem("Category", true);
                                  }}
                                >
                                  {e?.name}
                                </Link>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </li>
                  </>
                );
              })}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
