/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../config/https";
import {
  addToCartLogin,
  deleteFromCart,
} from "../../../store/slices/cart-slice";
import { toast } from "react-toastify";
import Loader from "../../../utils/loader";
import { useCallback } from "react";

const MenuCart = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const isLogin = useSelector((state) => state.authSlice);
  console.log(isLogin, "login");
  let cartTotalPrice = 0;
  console.log(cartItems, "cartItems");
  const getCart = useCallback(() => {
    axiosInstance.get("carts").then((e) => {
      const Data = e.data.cart.map((x) => ({
        id: x.id,
        cart_id: x.product_id,
        name: x.product.name,
        slug: x.product.slug,
        qty: x.qty,
        price: x.price,
        image: x.product.product_images[0].image,
      }));
      console.log(Data, e, "apiC");
      if (e.status === 200 || Data.length > 1) {
        dispatch(addToCartLogin(Data));
      } else {
      }
    });
  }, [dispatch]);
  const DeleteCart = (id) => {
    setLoader(true);

    axiosInstance.delete(`carts/${id}`).then((e) => {
      if (e.status === 200 || e.status === 201) {
        toast.success("Delete SuccessFully");
        getCart();
        setLoader(false);
      }
      setLoader(false);
    });
    setLoader(false);
  };
  useEffect(() => {
    if (isLogin.isAuthenticate === true) {
      getCart();
    }
  }, [isLogin.isAuthenticate]);

  return (
    <div className="shopping-cart-content">
      {loader ? <Loader /> : null}
      {cartItems && cartItems?.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems?.map((item) => {
              console.log(item, "item?.cart_id");
              console.log(item, item.price * item.quantity, "itemitem");
              cartTotalPrice += item.price * item.qty;

              return (
                <li className="single-shopping-cart" key={item.id}>
                  <div className="shopping-cart-img">
                    <Link to={"/product/Furniture/" + item.slug}>
                      <img
                        alt="no img"
                        src={`https://verifiedcalendar.dev-hi.xyz/storage/product/${item.image}
                             
                          `}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={"/product/Furniture/" + item.slug}>
                        {" "}
                        {item?.name}{" "}
                      </Link>
                    </h4>
                    <h6>Qty: {item?.qty}</h6>
                    <span>
                      <i className="fa fa-dollar"> </i>
                      {item.price}
                    </span>
                    {/* {item.selectedProductColor && item.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>Color: {item?.selectedProductColor}</span>
                        <span>Size: {item?.selectedProductSize}</span>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() => {
                        if (isLogin.isAuthenticate) {
                          DeleteCart(item?.id);
                        } else {
                          dispatch(deleteFromCart(item?.id));
                        }
                      }}
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total :{" "}
              <span className="shop-total">
                {" "}
                <i className="fa fa-dollar"> </i>
                {cartTotalPrice.toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

export default MenuCart;
