import { Suspense } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./assets/OwaisStyle.css";
import ScrollToTop from "./helpers/scroll-top";
import { AuthRoutes, ProfessionalRoutes, UserRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import Loader from "./utils/loader";
import "react-dropzone-uploader/dist/styles.css";

// import "/node_modules/video-react/dist/video-react.css"; // import css

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* AUTH */}
            {AuthRoutes.map((e, i) => (
              <Route
                key={i}
                path={process.env.PUBLIC_URL + e.path}
                element={
                  <Authmiddleware isProtected={e.isProtected}>
                    {e.component}
                  </Authmiddleware>
                }
                exact
              />
            ))}
            {/* AUTH */}

            {/* USER */}
            {UserRoutes.map((e, i) => (
              <Route
                key={i}
                path={process.env.PUBLIC_URL + e.path}
                element={
                  <Authmiddleware isProtected={e.isProtected}>
                    {e.component}
                  </Authmiddleware>
                }
                exact
              />
            ))}
            {/* USER */}

            {/* PROFESSIONAL ROUTES  */}
            {ProfessionalRoutes.map((e, i) => (
              <Route
                key={i}
                path={process.env.PUBLIC_URL + e.path}
                element={
                  <Authmiddleware isProtected={e.isProtected}>
                    {e.component}
                  </Authmiddleware>
                }
                exact
              />
            ))}
            {/* PROFESSIONAL ROUTES  */}
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
