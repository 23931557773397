/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ProfileHeader from "../../../../components/profile-components/Profileheader/ProfileHeader";
import SEO from "../../../../components/seo";
import { axiosInstance } from "../../../../config/https";
import LayoutOne from "../../../../layouts/LayoutOne";
import Breadcrumb from "../../../../wrappers/breadcrumb/Breadcrumb";
import { useEffect } from "react";

const UserOrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState([]);
  const getallOrder = () => {
    axiosInstance.get(`customer/orders/${id}`).then((e) => {
      const Data = e?.data;
      console.log(Data.order.products, "GetAllorder");
      if (e.status === 200) {
        setOrder(Data?.order);
      }
    });
  };
  useEffect(() => {
    getallOrder();
  }, []);
  let { pathname } = useLocation();
  return (
    <Fragment>
      <SEO
        titleTemplate="User Home"
        description="User home of verified calendar"
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "User Profile",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <ProfileHeader />
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-lg-3">
              <h5 className="mb-4 " style={{ fontWeight: "bold" }}>
                Orders
              </h5>
              <h6>
                <Link to="/orders">Your Orders</Link>
              </h6>
              <h6>
                <Link to="/ordermessage">Order Messages</Link>
              </h6>
              <h6>
                <Link to="/ordersent">Sent Messages</Link>
              </h6>

              <hr style={{ width: "70%" }} />
              <h5 className="mb-4" style={{ fontWeight: "bold" }}>
                Shopping Account
              </h5>
              <h6>
                <Link to="/billinginfo">Billing Info</Link>
              </h6>
              <h6>
                <Link to="/shippingaddress">Shipping Addresses</Link>
              </h6>
              <div className="row">
                <div className="col-md-6 mt-2"></div>
              </div>

              <div className="row mt-4">{/* <BlogPosts /> */}</div>
            </div>

            <div className="col-lg-8 ">
              <h1 className="my-3">Order Details</h1>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-content table-responsive cart-table-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Status</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order?.products?.map((orderItem, i) => {
                          i = i + 1;
                          return (
                            <tr>
                              <td>{i}</td>
                              <td className="product-thumbnail">
                                <Link
                                  to={"/product/Furniture/" + orderItem.slug}
                                >
                                  <img
                                    className="img-fluid"
                                    src={
                                      "https://verifiedcalendar.dev-hi.xyz/storage/product/" +
                                      orderItem?.product_images[0]?.image
                                    }
                                    alt="no img found"
                                  />
                                </Link>
                              </td>
                              <td className="product-name text-center text-capitalize">
                                <Link
                                  to={"/product/Furniture/" + orderItem.slug}
                                >
                                  {orderItem.name}
                                </Link>
                              </td>

                              <td className="product-price-cart">
                                <i className="fa fa-dollar"> </i>
                                {orderItem.sale_price !== null
                                  ? orderItem.sale_price
                                  : orderItem.regular_price}
                              </td>

                              <td className="product-quantity">
                                {order.status}
                              </td>

                              <td className="product-remove">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: orderItem.description,
                                  }}
                                />
                              </td>
                              <td className="product-remove">
                                <Link
                                  to={"/product/Furniture/" + orderItem.slug}
                                >
                                  <div className="btn-outline-success p-2">
                                    View
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default UserOrderDetails;
