const { createSlice } = require('@reduxjs/toolkit');

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticate: false,
        user: {}
    },
    reducers: {
        setIsAuthenticate(state, action) {
            state.isAuthenticate = action.payload.isAuth;
            state.user = action.payload.user;
        },
        logout(state, action) {
            state.isAuthenticate = false;
            localStorage.clear();
        }
    },
});

export const { setIsAuthenticate , logout} = authSlice.actions;
export default authSlice.reducer;
