import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import LayoutOne from "../../../../layouts/LayoutOne";
import ProfileHeader from "../../../../components/profile-components/Profileheader/ProfileHeader";
import SEO from "../../../../components/seo";
import Breadcrumb from "../../../../wrappers/breadcrumb/Breadcrumb";

const UserOrderSent = () => {
  let { pathname } = useLocation();
  return (
    <Fragment>
      <SEO
        titleTemplate="Professional Home"
        description="Professional home of verified calendar"
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Professional Profile",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <ProfileHeader />
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-lg-3">
              <h5 className="mb-4 " style={{ fontWeight: "bold" }}>
                Orders
              </h5>
              <h6>
                <Link to="/orders">Your Orders</Link>
              </h6>
              <h6>
                <Link to="/ordermessage">Order Messages</Link>
              </h6>
              <h6>
                <Link to="/ordersent">Sent Messages</Link>
              </h6>

              <hr style={{ width: "70%" }} />
              <h5 className="mb-4" style={{ fontWeight: "bold" }}>
                Shopping Account
              </h5>
              <h6>
                <Link to="/billinginfo">Billing Info</Link>
              </h6>
              <h6>
                <Link to="/shippingaddress">
                  Shipping Addresses
                </Link>
              </h6>
              <div className="row">
                <div className="col-md-6 mt-2"></div>
              </div>

              <div className="row mt-4">{/* <BlogPosts /> */}</div>
            </div>

            <div className="col-lg-8  ">
              <h1 className="mt-3">Sent Messages</h1>
              <h6 className="mt-3">You do not have any order messages.</h6>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default UserOrderSent;
